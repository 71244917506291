import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import config from '@plone/volto/registry';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import Api from '@plone/volto/helpers/Api/Api';
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';

const formatContent = (content) => {
  if (!content) return content;

  const { blocks_layout = {}, blocks = {} } = content;
  const newItems =
    blocks_layout?.items?.filter(
      (itemId) => blocks[itemId]?.['@type'] !== 'title',
    ) || [];

  return {
    ...content,
    blocks_layout: {
      ...blocks_layout,
      items: newItems,
    },
  };
};

const findBestFit = (url, mappings) => {
  const sortedMappings = mappings.sort((a, b) => b.path.length - a.path.length);
  for (let mapping of sortedMappings) {
    if (url.startsWith(mapping.path)) {
      return mapping.uid;
    }
  }
  return null;
};

const Footer = (props) => {
  const [footerContent, setFooterContent] = useState(null);
  const footerFragments = config.settings.footerFragments || [];
  const footerMappings = useSelector(
    (state) => state?.lightsite?.editable_footer_mapping,
  );
  const { token } = useSelector((state) => state.userSession);
  const location = useLocation();

  useEffect(() => {
    const api = new Api();
    try {
      if (!footerMappings) return;

      const currentUrl = getBaseUrl(location.pathname);

      const uid = findBestFit(currentUrl, JSON.parse(footerMappings));

      const fetchFooterContent = async () => {
        const response = await api.get(`/@search?UID=${uid}`, {
          params: {
            fullobjects: 1,
          },
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const content = response['items'][0];
        setFooterContent(formatContent(content));
      };
      fetchFooterContent();
    } catch (error) {
      console.error('Error fetching footer content:', error);
    }
  }, [footerMappings, location.pathname, token]);

  return (
    <footer id="footer">
      {footerContent && (
        <div className="lightsite-editable-footer">
          <RenderBlocks content={footerContent} />
        </div>
      )}
      {footerFragments.map((FooterFragment, index) => (
        <FooterFragment key={index} />
      ))}
    </footer>
  );
};

export default Footer;
