import React from 'react';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { injectIntl } from 'react-intl';
import Logo from '@plone/volto/components/theme/Logo/Logo';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import {
  PortalFacebookIcon,
  PortalInstagramIcon,
  PortalLinkedInIcon,
  PortalTwitterIcon,
  PortalYoutubeIcon,
} from 'volto-jyu-light-theme-addon/icons';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  privacyNotice: {
    id: 'Privacy notice',
    defaultMessage: 'Privacy notice',
  },
  accessibility: {
    id: 'Accessibility',
    defaultMessage: 'Accessibility',
  },
});

const JYUThemeFooter = ({ intl }) => {
  const themeEnabled = useSelector(
    (state) => state?.lightsite?.jyu_light_theme_enabled,
  );
  const lang = useSelector((state) => state.intl.locale);

  const prefixPath = config.settings.prefixPath || '';

  const privacyNoticeUrl =
    lang === 'fi'
      ? `${prefixPath}/fi/tietosuojailmoitus`
      : `${prefixPath}/${lang}/privacy-notice`;
  const accessibilityUrl =
    lang === 'fi'
      ? `${prefixPath}/fi/saavutettavuus`
      : `${prefixPath}/${lang}/accessibility`;

  return themeEnabled ? (
    <div className="jyu-theme-footer-container">
      <div className="logo">
        <Logo />
      </div>

      <div className="social-icons">
        <a
          aria-label="Facebook"
          href="https://www.facebook.com/JyvaskylaUniversity"
        >
          <Icon name={PortalFacebookIcon} size="23px" className="test" />
        </a>
        <a
          aria-label="Instagram"
          href="https://www.instagram.com/uniofjyvaskyla"
        >
          <Icon name={PortalInstagramIcon} size="23px" />
        </a>
        <a aria-label="LinkedIn" href="https://www.linkedin.com/school/166642/">
          <Icon name={PortalLinkedInIcon} size="23px" />
        </a>
        <a aria-label="Twitter" href="https://twitter.com/uniofjyvaskyla">
          <Icon name={PortalTwitterIcon} size="23px" />
        </a>
        <a
          aria-label="YouTube"
          href="https://www.youtube.com/user/JyvaskylaUniversity"
        >
          <Icon name={PortalYoutubeIcon} size="23px" />
        </a>
      </div>
      <div className="footer-end-row">
        <a className="footer-privacy-notice" href={privacyNoticeUrl}>
          {intl.formatMessage(messages.privacyNotice)}
        </a>
        <span className="footer-bar"> | </span>
        <a className="footer-accessibility" href={accessibilityUrl}>
          {intl.formatMessage(messages.accessibility)}
        </a>
      </div>
    </div>
  ) : null;
};

export default injectIntl(JYUThemeFooter);
