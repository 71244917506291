// SemanticUI-free pre-@plone/components
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { toBackendLang } from '@plone/volto/helpers/Utils/Utils';
import DefaultLogo from '@plone/volto/components/theme/Logo/Logo.svg';
import JYULogo from './JYULogo.svg';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const messages = defineMessages({
  site: {
    id: 'Site',
    defaultMessage: 'Site',
  },
  plonesite: {
    id: 'Plone Site',
    defaultMessage: 'Plone Site',
  },
});

const Logo = () => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();
  const site = useSelector((state) => state.site.data);
  const themeEnabled = useSelector(
    (state) => state?.lightsite?.jyu_light_theme_enabled,
  );

  const pickLogo = () => {
    if (themeEnabled) {
      return JYULogo;
    } else if (site['plone.site_logo']) {
      return `${settings.publicURL}/++api++/${flattenToAppURL(
        site['plone.site_logo'],
      )}`;
    } else {
      return DefaultLogo;
    }
  };
  const pickTitle = () => {
    if (themeEnabled) {
      return 'Jyväskylän yliopisto';
    } else if (site['plone.site_logo']) {
      return site['plone.site_title'];
    } else {
      return intl.formatMessage(messages.plonesite);
    }
  };

  return (
    <UniversalLink
      href={settings.isMultilingual ? `/${toBackendLang(lang)}` : '/'}
      title={pickTitle()}
    >
      <div className="logo-container">
        <img src={pickLogo()} alt={pickTitle()} title={pickTitle()} />
        {themeEnabled && <span>Jyväskylän yliopisto</span>}
      </div>
    </UniversalLink>
  );
};

export default Logo;
