import CustomCSS from '@package/components/theme/CustomCSS/CustomCSS';
import CustomJS from '@package/components/theme/CustomJS/CustomJS';
import LightsiteSettings from '@package/components/theme/LightsiteSettings/LightsiteSettings';
import GlobalColorVariables from '@package/components/theme/GlobalColorVariables/GlobalColorVariables';
import FooterMappingWidget from '@package/widgets/FooterMappingWidget';

import { readFromEnvVar } from '@package/helpers';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

// Define new colors to override volto-light-theme one's.
const BG_COLORS = [
  { name: 'lightsite-main-color-1', label: 'Main Color 1' },
  { name: 'lightsite-main-color-2', label: 'Main Color 2' },
  { name: 'lightsite-main-color-3', label: 'Main Color 3' },
];

const HIGHLIGHT_COLORS = [
  { name: 'lightsite-main-color-1', label: 'Main Color 1' },
  { name: 'lightsite-main-color-2', label: 'Main Color 2' },
  { name: 'lightsite-main-color-3', label: 'Main Color 3' },
];

function getSupportedLanguages(envVarValue, defaultValue) {
  // Split the string by commas and filter out any empty strings
  return envVarValue
    ? envVarValue.split(',').filter((lang) => lang.trim() !== '')
    : defaultValue;
}

export default function applyConfig(config) {
  config.settings.isMultilingual = true;

  config.widgets.id = {
    ...config.widgets.id,
    editable_footer_mapping: FooterMappingWidget,
  };

  // Possibly read language settings from an env variable
  config.settings.supportedLanguages = getSupportedLanguages(
    readFromEnvVar('RAZZLE_SUPPORTED_LANGUAGES'),
    ['fi', 'en'],
  );
  config.settings.defaultLanguage = config.settings.supportedLanguages[0];

  config.settings.prefixPath = readFromEnvVar('RAZZLE_PREFIX_PATH', '');

  // Add new themes from BG_COLORS
  BG_COLORS.forEach((color) => {
    config.blocks.themes.push({
      name: color.name,
      label: color.label,
      // Default values come from the default theme, we will override with our colors in css!
      style: {
        '--theme-color': '#fff',
        '--theme-high-contrast-color': '#ecebeb',
        '--theme-foreground-color': '#000',
        '--theme-low-contrast-foreground-color': '#555555',
      },
    });
  });

  // Update hightlight block to have our customized colors, uses old-style system on upstream too
  config.blocks.blocksConfig.highlight = {
    ...config.blocks.blocksConfig.highlight,
    descriptionColors: HIGHLIGHT_COLORS,
  };

  // Whitelist blocks allowed for the footer component.
  const footerAllowedBlocks = ['slate', 'image', 'gridBlock', 'html'];
  Object.keys(config.blocks.blocksConfig).forEach((blockId, idx) => {
    const blockConfig = config.blocks.blocksConfig[blockId];
    const originalRestricted = blockConfig.restricted;

    blockConfig.restricted = ({ contentType }) => {
      if (contentType === 'Footer') {
        return !footerAllowedBlocks.includes(blockId);
      }
      return originalRestricted;
    };
  });
  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    'image',
    'listing',
    'slate',
    'teaser',
  ];

  const appExtras = [
    {
      match: '',
      component: LightsiteSettings,
    },
    {
      match: '',
      component: CustomCSS,
    },
    {
      match: '',
      component: CustomJS,
    },
    {
      match: '',
      component: GlobalColorVariables,
    },
  ];
  config.settings.appExtras = [...config.settings.appExtras, ...appExtras];

  return config;
}
